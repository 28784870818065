:root {
  background-color: #282c34;
  padding: 0;
  margin: 0;
}

h1 {
  margin: 0;
}

h3 {
  margin-top: 0;
}

li {
  font-size: 24px;
  text-align: left;
}

p {
  margin: 0;
  padding: 0;
}

input {
  width: 130px;
  height: auto;
  font-size: 32px;
  border: none;
  border-radius: 30px;
}

button {
  font-size: 32px;
  border: 1px solid #64676c;
  border-radius: 10px;
}

.intro {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: white;
  gap: 60px;
}

.mastermind {
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: white;
  gap: 20px;
}

.headercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.gamecontainer {
  display: flex;
  gap: 20px;
}

.round {
  width: 60px;
  text-align: right;
}

/* .solution {
  padding-right: 94px;
} */

.solutionAndButton {
  display: flex;
  align-items: center;
  gap: 27px;
  padding-left: 78px;
}

.solutionAndButton button {
  font-size: 20px;
  height: 32px;
}

.gameTitle {
  background: #64676c;
  width: 368px;
  text-align: center;
  border-radius: 30px;
}